import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ConfigurationService, SectionTitle } from '../../../internal';
import { handleConfigurationUpdate, updatePartsOfPieces } from './index';
import { useTranslation } from 'react-i18next';
import { EXTERIOR, INTERIOR } from '../../../constants/Values';
import * as Materials from '../../../constants/Materials';
import { GRAY, STANDARD } from '../../../constants/MaterialColors';
import { NORMAL } from '../../../constants/MaterialQualities';
import { getTypesByPreset, REAR_HEEL_1_2, REAR_HEEL_5_2, TYPE_10, TYPE_9 } from '../../../constants/ObjectTypes';
import {
	CONFIGURABLE_OPERATIONS,
	CUSHIONS,
	EXTERIOR_PRESETS,
	INTERIOR_PRESETS,
	REAR_HEELS,
	SILLS,
	SILLS_CUSTOM,
	SILLS_STOCK,
} from '../../../constants/Presets';
import { useDispatch, useSelector } from 'react-redux';
import { DANGER, SUCCESS } from '../../../constants/Variants';
import { addAlertMessage, setConfirmationModal, setWindowIsLoading } from '../../../actions/GeneralActions';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { setCurrentConfiguration, setCurrentOffer, setCurrentPiece } from '../../../actions/OfferActions';
import history from '../../../config/history';
import { animateScroll } from 'react-scroll';
import { ValidationMessage } from '../../shared/ValidationMessage';
import { useForm } from 'react-hook-form';
import { OFFER, ORDER } from '../../../constants/ConfigurableTypes';
import { DecorSelect } from './DecorSelect';
import { PresetSelect } from './PresetSelect';
import { MaterialSelect } from './MaterialSelect';
import { TypeSelect } from './TypeSelect';
import { HeightSelect } from './HeightSelect';
import { QualitySelect } from './QualitySelect';
import { MaterialColorSelect } from './MaterialColorSelect';
import { CHISELED_SIDE, DEBASING, FINISHED_SIDE, PROFILE, WATERLIST } from '../../../constants/OperationTypes';
import { parseNumber } from '../../../classes/helpers/StringHelper';
import { BACK } from '../../../constants/ObjectSides';
import { Type9And10ProfileForm } from '../shared/Type9And10ProfileForm';
import { ConfigurationOptions } from '../../../classes/models/ConfigurationOptions';
import { ModalHolder } from '../ModalHolder';
import { PALM, THOMASPIRON } from '../../../constants/ArticleSubstitutionGroupNames';
import { useLocation, useParams } from 'react-router-dom';
import { setCurrentOrder } from '../../../actions/OrderActions';
import { OFFERS_PATH_NAME } from '../../../constants/PathNames';

function ConfigurationModal(props) {
	const [selectedDecor, setSelectedDecor] = useState(null);
	const [selectedPreset, setSelectedPreset] = useState(null);
	const [selectedSubPreset, setSelectedSubPreset] = useState(null);
	const [selectedMaterial, setSelectedMaterial] = useState(null);
	const [selectedMaterialColor, setSelectedMaterialColor] = useState(null);
	const [selectedQuality, setSelectedQuality] = useState(null);
	const [selectedType, setSelectedType] = useState(null);
	const [selectedHeight, setSelectedHeight] = useState(null);
	const [formIsValid, setFormIsValid] = useState(false);
	const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false);

	const { errors, register, handleSubmit, setValue, getValues } = useForm();

	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.configurationModal.';
	const validationPrefix = 'validation.';
	const operationTypePrefix = 'constants.operationTypes.';

	const currentConfiguration = useSelector(state => state.offerReducer.currentConfiguration);
	const currentOffer = useSelector(state => state.offerReducer.currentOffer);
	const currentOrder = useSelector(state => state.orderReducer.currentOrder);
	const windowIsLoading = useSelector(state => state.generalReducer.windowIsLoading);
	const { company, currentCompanyBranch } = useSelector(state => state.companyReducer);

	const dispatch = useDispatch();
	const urlParams = useParams();
	const location = useLocation();

	const configurationService = new ConfigurationService();

	useEffect(() => {
		if (!selectedHeight) {
			setValue('height', '', { shouldValidate: false });
		} else {
			setValue('height', selectedHeight, { shouldValidate: true });
		}
	}, [selectedHeight]);

	useEffect(() => {
		if (selectedDecor && selectedPreset) {
			setShouldShowConfirmationModal(getNonApplicableOperations().length > 0);
		}
	}, [selectedDecor, selectedPreset]);

	useEffect(() => {
		if (currentConfiguration && props.isEditing) {
			const { options } = currentConfiguration;

			setSelectedDecor(options.decor);
			setSelectedPreset(options.preset);
			setSelectedSubPreset(options.subPreset);
			setSelectedMaterial(options.material);
			setSelectedMaterialColor(options.materialColor);
			setSelectedQuality(options.quality);
			setSelectedType(options.type);
			setSelectedHeight(parseNumber(options.height));
		} else {
			clearOptions();
		}
	}, [props.isActive]);

	useEffect(() => {
		// Check if form is valid

		let isValid =
			selectedDecor && selectedPreset && selectedMaterial && selectedMaterialColor && selectedType && selectedHeight;

		if (selectedMaterial === Materials.BLUESTONE && selectedDecor === EXTERIOR && !selectedQuality) isValid = false;

		if (formIsValid !== isValid) {
			setFormIsValid(isValid);
		}

		animateScroll.scrollMore(600, { containerId: 'optionsScrollContainer', smooth: 'easeInOutQuad', duration: 1300 });
	}, [
		selectedDecor,
		selectedPreset,
		selectedMaterial,
		selectedMaterialColor,
		selectedQuality,
		selectedType,
		selectedHeight,
	]);

	useEffect(() => {
		if (!selectedMaterialColor && selectedMaterial === Materials.BLUESTONE && selectedDecor === EXTERIOR) {
			setSelectedQuality(NORMAL);

			if (hasAnyArticleSubstitutionGroup([PALM, THOMASPIRON]) && selectedSubPreset !== SILLS_STOCK) {
				// Companies Palm and Thomas & Piron should have gray selected as standard
				setSelectedMaterialColor(GRAY);
			} else {
				setSelectedMaterialColor(STANDARD);
			}
		}
	}, [selectedMaterial]);

	const hasAnyArticleSubstitutionGroup = groups => {
		let entityWithGroup = null;

		if (currentCompanyBranch) entityWithGroup = currentCompanyBranch;
		else if (company) entityWithGroup = company;
		else return false;

		return groups.includes(entityWithGroup.articleSubstitutionGroup?.name);
	};

	const createNewConfiguration = options => {
		dispatch(setWindowIsLoading(true));
		let configurableType;

		if (urlParams.type === OFFER || location.pathname.includes(OFFERS_PATH_NAME)) configurableType = OFFER;
		else configurableType = ORDER;

		const configurable = configurableType === OFFER ? currentOffer : currentOrder;

		configurationService
			.create(configurable.id, configurableType, options)
			.then(response => {
				if (response.success) {
					dispatch(addAlertMessage(SUCCESS, t(prefix + 'creatingConfigurationSuccess')));

					configurable.configurations.push(response.data);

					let newCurrentPiece = response.data.pieces.length > 0 ? response.data.pieces[0] : null;

					if (configurableType) dispatch(setCurrentOffer(currentOffer));
					else dispatch(setCurrentOrder(currentOrder));
					dispatch(setCurrentConfiguration(response.data));
					dispatch(setCurrentPiece(newCurrentPiece));

					if (history.location.pathname.includes('/offers') || history.location.pathname.includes('/orders')) {
						history.push(`/configurator/${configurableType}/${currentOffer.id}/${response.data.id}`);
					}

					props.onClose();
				} else {
					dispatch(addAlertMessage(DANGER, t(prefix + 'createConfigurationFailed')));
				}
			})
			.catch(ers => {
				if (ers != null && Object.values(ers).length > 0) {
					dispatch(addAlertMessage(DANGER, Object.values(ers)[0][0]));
				}
			})
			.finally(() => {
				dispatch(setWindowIsLoading(false));
			});
	};

	const updateConfigurations = options => {
		dispatch(setWindowIsLoading(true));

		let configurableType;

		if (urlParams.type === OFFER || location.pathname.includes(OFFERS_PATH_NAME)) configurableType = OFFER;
		else configurableType = ORDER;

		const configurable = configurableType === OFFER ? currentOffer : currentOrder;

		configurationService
			.updateOptions(OFFER, configurable.id, currentConfiguration.id, options)
			.then(optionsResponse => {
				if (optionsResponse.success) {
					return updatePartsOfPieces(optionsResponse.data);
				} else {
					throw Error(optionsResponse.message);
				}
			})
			.then(partsResponse => {
				if (partsResponse.success) {
					handleConfigurationUpdate(partsResponse.data, () => props.onClose(true));
				} else {
					throw Error(partsResponse.message);
				}
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'updateConfigurationFailed')));
				throw error;
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const openConfirmationModal = () => {
		const nonApplicableOperations = getNonApplicableOperations();
		let operationTypes = '';
		nonApplicableOperations.forEach((operationType, index) => {
			let translatedOperation = t(operationTypePrefix + operationType);
			translatedOperation += nonApplicableOperations.length === index + 1 ? '' : ' - ';

			operationTypes += translatedOperation.toLowerCase();
		});

		dispatch(
			setConfirmationModal({
				isActive: true,
				content: t(prefix + 'removeOperationConfirmationMessage', { operationTypes }),
				onAccept: () => onClose(getValues()),
			}),
		);
	};

	const getNonApplicableOperations = () => {
		if (!currentConfiguration || !props.isEditing) return [];

		let nonApplicableOperations = [];

		currentConfiguration.pieces.forEach(piece => {
			const operations = piece.operations.filter(
				o => ![FINISHED_SIDE, CHISELED_SIDE, WATERLIST, DEBASING].includes(o.type),
			);

			operations.forEach(o => {
				if (
					!nonApplicableOperations.includes(o.type) &&
					!CONFIGURABLE_OPERATIONS[selectedDecor][selectedPreset].includes(o.type)
				) {
					nonApplicableOperations.push(o.type);
				}
			});
		});

		// Type 9 and 10 can only have a profile on the back side, so remove it from the non-applicable operations
		if (nonApplicableOperations.length && [TYPE_9, TYPE_10].includes(currentConfiguration.options.type)) {
			nonApplicableOperations = nonApplicableOperations.filter(o => o.type === PROFILE && o.side === BACK);
		}

		return nonApplicableOperations;
	};

	const setDecor = decor => {
		setSelectedDecor(decor);

		// Reset the rest of the form
		setSelectedPreset(null);
		setSelectedMaterial(null);
		setSelectedMaterialColor(null);
		setSelectedType(null);
		setSelectedHeight(null);
	};

	const setPreset = preset => {
		setSelectedPreset(preset);

		if (preset === SILLS && !selectedSubPreset) setSubPreset(SILLS_CUSTOM);
		if (selectedSubPreset && preset !== SILLS) setSubPreset(null);

		setDefaultType(preset, selectedSubPreset);

		setSelectedHeight(null);
	};

	const setSubPreset = subPreset => {
		setSelectedSubPreset(subPreset);

		setDefaultType(selectedPreset, subPreset);

		if (subPreset === SILLS_STOCK) {
			setSelectedHeight(5);
		}
	};

	const setMaterial = material => {
		setSelectedMaterial(material);

		// Reset the rest of the form
		setSelectedMaterialColor(null);
		setSelectedQuality(null);
		// For stock sills this should always be 5cm and is set above, so it should not be reset
		if (!selectedSubPreset) setSelectedHeight(null);
	};

	const setDefaultType = (preset, subPreset) => {
		const typesOfPreset = getTypesByPreset(selectedDecor, selectedMaterial, preset, subPreset);

		if (typesOfPreset.length === 1) {
			setSelectedType(typesOfPreset[0]);
		}
	};

	const getAvailableMaterials = () => {
		let materials;

		if (selectedDecor === EXTERIOR) {
			if ([REAR_HEELS, CUSHIONS].includes(selectedPreset) || selectedSubPreset === SILLS_STOCK) {
				materials = [Materials.BLUESTONE];
			} else {
				materials = Materials.EXTERIOR_MATERIALS;
			}
		} else {
			materials = Materials.INTERIOR_MATERIALS;
		}

		return materials;
	};

	const updateSelectedType = type => {
		setSelectedType(type);

		if (type === REAR_HEEL_1_2) setSelectedHeight(1);
		if (type === REAR_HEEL_5_2) setSelectedHeight(5);
	};

	const onClose = fields => {
		if (!fields) {
			props.onClose();
			return;
		}

		let options = {
			name: fields?.name,
			decor: selectedDecor,
			preset: selectedPreset,
			subPreset: selectedSubPreset,
			material: selectedMaterial,
			materialColor: selectedMaterialColor,
			quality: selectedQuality,
			type: selectedType,
			bevelHeight: parseNumber(fields?.profileHeight),
			bevelWidth: parseNumber(fields?.profileDepth),
		};

		if (fields?.height) {
			options.height = fields ? parseNumber(fields.height) : null;
		} else {
			options.height = parseNumber(selectedHeight);
		}

		if (!props.isEditing) {
			createNewConfiguration(options);
		} else {
			// Update configuration
			updateConfigurations(options);
		}
	};

	const getSelectedOptions = () => {
		return new ConfigurationOptions({
			decor: selectedDecor,
			preset: selectedPreset,
			subPreset: selectedSubPreset,
			type: selectedType,
			height: selectedHeight,
			quality: selectedQuality,
			material: selectedMaterial,
			materialColor: selectedMaterialColor,
		});
	};

	const clearOptions = () => {
		setSelectedDecor(null);
		setSelectedPreset(null);
		setSelectedSubPreset(null);
		setSelectedMaterial(null);
		setSelectedMaterialColor(null);
		setSelectedQuality(null);
		setSelectedType(null);
		setSelectedHeight(null);
	};

	const renderFooterButtons = () => {
		let buttonContent;

		if (props.isEditing) buttonContent = t(prefix + 'editButton');
		else buttonContent = t(prefix + 'createButton');

		return (
			<>
				<button
					data-cy="configurationModal-submitButton"
					type={shouldShowConfirmationModal ? 'button' : 'submit'}
					onClick={shouldShowConfirmationModal ? () => openConfirmationModal() : () => console.log()}
					form="configurationForm"
					className="button button--primary"
					disabled={!formIsValid || windowIsLoading}
				>
					{buttonContent}
				</button>
				<button
					data-cy="configurationModal-closeButton"
					type="button"
					className="button"
					data-dismiss="modal"
					onClick={() => onClose(false)}
				>
					{t(prefix + 'closeButton')}
				</button>
			</>
		);
	};

	const renderConfigurationNameInput = () => {
		if (!selectedHeight) return;

		let defaultName;

		if (props.isEditing) {
			defaultName = currentConfiguration?.name;
		} else {
			defaultName = '';
		}

		return (
			<>
				<SectionTitle content={t(prefix + 'configurationNameTitle')} />
				<div className="form-group">
					<label>{t(prefix + 'configurationNameLabel')}</label>
					<input
						data-cy="configurationModal-nameInput"
						type="text"
						placeholder={t(prefix + 'configurationNamePlaceholder')}
						name={'name'}
						defaultValue={defaultName}
						ref={register({
							maxLength: 100,
						})}
					/>
					{errors.name?.type === 'maxLength' ? (
						<ValidationMessage content={t(validationPrefix + 'maxLength', { maxLength: 100 })} variant={DANGER} />
					) : null}
					<small>{t(prefix + 'configurationNameSubText')}</small>
				</div>
			</>
		);
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal show={props.isActive} onHide={() => onClose()} animation={true} data-cy="configuration-modal">
				<Modal.Header data-cy="configurationModal-title">
					<Modal.Title>{props.isEditing ? t(prefix + 'titleEditing') : t(prefix + 'titleCreating')}</Modal.Title>
				</Modal.Header>
				<Modal.Body id="optionsScrollContainer">
					<form id="configurationForm" onSubmit={handleSubmit(onClose)}>
						<DecorSelect selectedDecor={selectedDecor} setDecor={decor => setDecor(decor)} />
						<PresetSelect
							isVisible={selectedDecor != null}
							presets={selectedDecor === EXTERIOR ? EXTERIOR_PRESETS : INTERIOR_PRESETS}
							selectedPreset={selectedPreset}
							setPreset={preset => setPreset(preset)}
							selectedSubPreset={selectedSubPreset}
							setSubPreset={subPreset => setSubPreset(subPreset)}
						/>
						<MaterialSelect
							isVisible={selectedPreset != null}
							materials={getAvailableMaterials()}
							selectedMaterial={selectedMaterial}
							setMaterial={material => setMaterial(material)}
						/>
						<TypeSelect
							dataCy="typeSelect"
							isVisible={
								selectedMaterial != null && getTypesByPreset(selectedDecor, selectedMaterial, selectedPreset).length > 1
							}
							types={getTypesByPreset(selectedDecor, selectedMaterial, selectedPreset, selectedSubPreset)}
							selectedOptions={getSelectedOptions()}
							setType={type => updateSelectedType(type)}
						/>
						{[TYPE_9, TYPE_10].includes(selectedType) && (
							<Type9And10ProfileForm
								maxHeight={20}
								formRegister={register}
								formErrors={errors}
								dimensions={{
									height: currentConfiguration?.options.bevelHeight,
									width: currentConfiguration?.options.bevelWidth,
								}}
							/>
						)}
						<HeightSelect
							isVisible={
								selectedDecor &&
								selectedMaterial &&
								((selectedDecor === EXTERIOR && selectedType) || (selectedDecor === INTERIOR && selectedMaterial)) &&
								![REAR_HEEL_1_2, REAR_HEEL_5_2].includes(selectedType)
							}
							selectedOptions={getSelectedOptions()}
							setHeight={height => setSelectedHeight(parseNumber(height))}
							inputRegister={register}
							inputErrors={errors}
							isEditing={props.isEditing}
						/>
						<QualitySelect
							isVisible={
								selectedHeight && selectedType && selectedMaterial === Materials.BLUESTONE && selectedDecor === EXTERIOR
							}
							selectedOptions={getSelectedOptions()}
							setQuality={quality => setSelectedQuality(quality)}
							isEditing={props.isEditing}
						/>
						<MaterialColorSelect
							isVisible={selectedHeight && (selectedType || selectedDecor === INTERIOR)}
							selectedOptions={getSelectedOptions()}
							setColor={color => setSelectedMaterialColor(color)}
							dataCy={'configurationModal-materialColorSelect'}
						/>
						{renderConfigurationNameInput()}
					</form>
				</Modal.Body>
				<Modal.Footer>{renderFooterButtons()}</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
}

ConfigurationModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	isEditing: PropTypes.bool,
};

ConfigurationModal.defaultProps = {
	isEditing: false,
};

export { ConfigurationModal };
