import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	AppContent,
	AppHolder,
	AuthService,
	ConfigurationService,
	ConfirmationModal,
	EmptySidebarListItem,
	getRotationByPreset,
	LoadingMessage,
	MeshCreator,
	MessageHolder,
	NavigationBar,
	OfferService,
	SearchBar,
	SideBar,
	SidebarList,
	SidebarListItem,
} from '../../../internal';

import _ from 'lodash';
import * as ThreeConstants from '../../../constants/ThreeConstants';
import * as GeneralActions from '../../../actions/GeneralActions';
import * as ConfiguratorActions from '../../../actions/ConfiguratorActions';
import * as OfferActions from '../../../actions/OfferActions';
import * as Values from '../../../constants/Values';
import * as ObjectSides from '../../../constants/ObjectSides';
import { FRONT, TOP } from '../../../constants/ObjectSides';

import {
	AmbientLight,
	Color,
	FontLoader,
	GridHelper,
	MathUtils,
	Mesh,
	MeshStandardMaterial,
	Object3D,
	PerspectiveCamera,
	Scene,
	ShapeBufferGeometry,
	Vector3,
	WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { withTranslation } from 'react-i18next';
import { PieceService } from '../../../classes/services/PieceService';
import { Canvas3D } from './Canvas3D';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { PieceList } from './PieceList';
import { DANGER, LIST_ITEM, SUCCESS } from '../../../constants/Variants';
import history from '../../../config/history';
import exteriorIcon from '../../../assets/img/icon-out.svg';
import interiorIcon from '../../../assets/img/icon-in.svg';
import stockIcon from '../../../assets/img/icon-stock.svg';
import ReactTooltip from 'react-tooltip';
import DisjointSet from 'disjoint';
import ConfigurableReducerHelper from '../../../classes/helpers/ConfigurableReducerHelper';
import { InfoModal } from '../../modals/InfoModal';
import { OFFER, ORDER } from '../../../constants/ConfigurableTypes';
import { OrderService } from '../../../classes/services/OrderService';
import { setCurrentOrder } from '../../../actions/OrderActions';
import icon50 from '../../../assets/icons/50x50.png';
import { ConfigurationModal } from '../../modals/configurationModal/ConfigurationModal';
import { ConfiguratorAppHeader } from './ConfiguratorAppHeader';
import { getCameraPositionByAspectForObject, getPositionToPointCameraAt } from '../../../classes/helpers/CameraHelper';
import { OperationModal } from '../../modals/OperationModal';
import { SurfaceMeasurementLineCreator } from '../../../classes/services/SurfaceMeasurementLineCreator';
import { ConfigurationActionsDropdown } from './ConfigurationActionsDropdown';
import { ADMIN_CAN_EDIT_OFFERS } from '../../../constants/AdminSettings';
import { captureException } from '@sentry/react';
import { UPRIGHT_PRESETS, VERTICAL_DISPLAYED_PRESETS } from '../../../constants/Presets';
import { hasOneOfRoles } from '../../../classes/helpers/UserHelper';
import { ConfiguratorContext } from '../../../context/ConfiguratorContext';
import { EDITING_OFFER_ON, EDITING_ORDER_OFF, EDITING_ORDER_ON } from '../../../constants/ConfiguratorStatuses';
import ImageCreator from '../../../classes/services/ImageCreator';
import { DISCOUNTED } from '../../../constants/PriceTypes';
import { ADMIN, MULTI_BRANCH_USER, SALES, SUPERVISOR, USER } from '../../../constants/RoleNames';
import { getSidesIncludedInPrice } from '../../../classes/helpers/FinishedSidesHelper';

class Configurator extends Component {
	constructor(props) {
		super(props);
		this.canvas = React.createRef();
		this.state = {
			price: 0,
			priceLoading: false,
			selectedCameraAngle: 1,
			accordionActiveKey: 1,
			connectObjectIsActive: false,
			threeDimensionalIsEnabled: false,
			addObjectModalIsActive: false,
			options: null,
			deleteConfigurationModalIsActive: false,
			perspectiveIcon: Values.TWO_DIMENSIONAL,
			profileModalIsActive: false,

			currentConfiguration: null,
			currentObject: null,
			currentReferenceName: '',

			offer: [],
			aspect: Values.TWO_DIMENSIONAL,
			view: ObjectSides.TOP,
			searchKeyword: '',
			deleteOfferModalIsActive: false,
			configurationModalIsActive: false,
			isEditingConfiguration: false,
			configurationIdToDelete: 0,
			configurationDeleteModalIsActive: false,
			showPriceChangeInfoModal: false,
		};

		//region Class variables
		this.authService = new AuthService();
		this.offerService = new OfferService();
		this.orderService = new OrderService();
		this.configurationService = new ConfigurationService();
		this.pieceService = new PieceService();
		this.measurementLineCreator = new SurfaceMeasurementLineCreator();
		this.meshCreator = new MeshCreator();
		this.configurableReducerHelper = new ConfigurableReducerHelper();

		this.gridHelper = new GridHelper(3000, 50, 0xc9d5e3, 0xc9d5e3);
		this.gridHelper.position.add(new Vector3(0, -100, 0));

		this.prefix = 'pages.configurator.';
		this.appHeaderPrefix = 'headers.appHeader.actions.';

		this.images = require.context('../../../assets/img/configuration');

		this.isRefreshingCanvas = false;
		this.renderingId = null;
		//endregion
	}

	static clearCanvas(scene) {
		// Remove everything of the scene
		while (scene?.children.length > 0) {
			scene.remove(scene.children[0]);
		}
	}

	canUserEdit() {
		let canEdit = true;

		if (hasOneOfRoles([ADMIN, SUPERVISOR, SALES])) {
			if (this.props.match.params.type === OFFER) {
				const offerBelongsToAdmin =
					this.props.currentUser?.companyBranch?.id === this.props.currentOffer?.companyBranch?.id;
				canEdit = this.props.settings[ADMIN_CAN_EDIT_OFFERS] || offerBelongsToAdmin;
			} else {
				canEdit = this.props.configuratorStatus === EDITING_ORDER_ON;
			}
		} else if (hasOneOfRoles(MULTI_BRANCH_USER)) {
			canEdit = this.props.currentUser?.company?.branches
				?.map(b => b.id)
				.includes(this.props.currentOffer?.companyBranch?.id);
		} else {
			if (this.props.match.params.type === ORDER) canEdit = false;
		}

		return canEdit;
	}

	componentDidMount() {
		this.initScene();

		this.props.setConfigurableType(this.props.match.params.type);

		if (this.props.match.params.type === ORDER) {
			this.props.setConfiguratorStatus(EDITING_ORDER_OFF);
		} else {
			// OFFER
			this.props.setConfiguratorStatus(EDITING_OFFER_ON);
		}

		if (this.props.match.params.type === OFFER) {
			this.getCurrentOffer();
		}

		if (this.props.match.params.type === ORDER) {
			this.getCurrentOrder();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const canEdit = this.canUserEdit();

		if (this.props.canEdit !== canEdit) this.props.setCanEdit(canEdit);

		if (prevProps.configuratorStatus === EDITING_ORDER_ON && this.props.configuratorStatus !== EDITING_ORDER_ON) {
			const ignoreResult = this.updateOrder();
		}

		if (
			prevProps.currentConfiguration?.id !== this.props.currentConfiguration?.id ||
			prevProps.currentConfiguration?.pieces.length !== this.props.currentConfiguration?.pieces.length ||
			prevProps.currentPiece?.id !== this.props.currentPiece?.id
		) {
			const ignoreResult = this.refreshCanvas(true);
		}

		if (
			(!prevProps.shouldUpdatePrice && this.props.shouldUpdatePrice) ||
			prevProps.priceType !== this.props.priceType
		) {
			if (this.props.match.params.type === OFFER) {
				if (canEdit) {
					this.getCurrentOffer();
				}
			} else {
				this.getCurrentOrder();
			}

			if (this.props.shouldUpdatePrice) {
				this.props.setShouldUpdatePrice(false);
			}
		}

		if (prevProps.currentPiece == null && this.props.currentPiece != null) this.refreshCanvas();

		if (!this.props.currentPiece) this.handleCameraChange();
	}

	//region THREE.js

	componentWillUnmount() {
		if (this.props.match.params.type === ORDER && this.props.configuratorStatus === EDITING_ORDER_ON) {
			let ignore = this.updateOrder();
		}

		cancelAnimationFrame(this.renderingId);
	}

	initScene() {
		//region Init camera/scene
		this.scene = new Scene();
		this.scene.background = new Color(ThreeConstants.BACKGROUND_COLOR);
		const canvas = this.canvas.current;

		this.camera = new PerspectiveCamera(Values.FOV, window.innerWidth / window.innerHeight, 1, 100000);
		this.camera.aspect = canvas.width / canvas.height;
		this.camera.position.add(new Vector3(0, 200, 0));

		this.camera.updateProjectionMatrix();

		// Get canvas ref initialized in constructor
		this.renderer = new WebGLRenderer({ canvas, antialias: true });
		this.renderer.setSize(canvas.width, canvas.height);

		//endregion

		this.offset = new Vector3();
		this.selected = null;

		//region Controls
		// Create an orbitcontrol
		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.controls.minDistance = 50;
		this.controls.maxDistance = 1000;
		this.controls.enableZoom = true;
		this.controls.enablePan = false;
		this.controls.enableRotate = false;
		//endregion

		this.animate();
	}

	async refreshCanvas(updateCameraPosition = false) {
		// Clear the canvas, so no objects are rendered twice
		if (this.scene == null) return;

		let configurationObject = new Object3D();

		const onLoad = (font, piece) => {
			let xMid, text;
			const fontSize = ThreeConstants.PIECE_NAME_FONT_SIZE;

			let color =
				this.props.currentPiece?.id === piece.id
					? new Color(ThreeConstants.HIGHLIGHTED_TEXT)
					: new Color(ThreeConstants.TEXT);

			let matLite = new MeshStandardMaterial({
				color: color,
				transparent: false,
				opacity: 0.4,
			});

			let shapes = font.generateShapes(piece.name, fontSize);

			let geometry = new ShapeBufferGeometry(shapes);
			geometry.computeBoundingBox();
			xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
			geometry.translate(xMid, 0, 0);

			// make shape ( N.B. edge view not visible )
			text = new Mesh(geometry, matLite);
			text.position.add(new Vector3(0, piece.dimensions.height / 2 + 1, fontSize / 3));
			text.rotateX(MathUtils.degToRad(-90));
			let newObject = this.meshCreator.createMeshFromPiece(
				piece,
				{
					type: piece.type,
					preset: this.props.currentConfiguration.options.preset,
					standardFinishedSides: getSidesIncludedInPrice(piece, this.props.currentConfiguration.options.preset),
				},
				text,
				this.props.currentPiece.id === piece.id,
			);
			newObject.matrixAutoUpdate = false;
			newObject.updateMatrix();

			const measurementLines = this.measurementLineCreator.createMeasurementLines(
				piece,
				piece.operations,
				piece.type,
				font,
			);

			newObject.add(...measurementLines);
			newObject.name = piece.id;

			return newObject;
		};

		let loader = new FontLoader();

		if (this.props.currentConfiguration != null) {
			const piecesToRender = this.getPiecesToRenderDisjoint();

			loader.loadAsync(process.env.PUBLIC_URL + '/fonts/Poppins_Regular.json').then(font => {
				Configurator.clearCanvas(this.scene);

				//region Add lighting to the scene
				// This has to be done here because the canvas is cleared every time something is drawn
				this.light = new AmbientLight(0xffffff, 2);
				this.light.position.set(0, 10, 300);
				this.scene.add(this.light);

				if (this.state.threeDimensionalIsEnabled) {
					this.scene.add(this.gridHelper);
				}
				//endregion

				piecesToRender.forEach(piece => {
					if (piece.dimensions.length < 1 || piece.dimensions.width < 1) return;

					configurationObject.add(onLoad(font, piece));
				});

				if (!configurationObject.name) configurationObject.name = this.props.currentConfiguration.id;

				const rotationByPreset = getRotationByPreset(this.props.currentConfiguration.options.preset);
				configurationObject.rotateX(MathUtils.degToRad(rotationByPreset.x));
				configurationObject.rotateY(MathUtils.degToRad(rotationByPreset.y));
				configurationObject.rotateZ(MathUtils.degToRad(rotationByPreset.z));
				configurationObject.updateMatrix();

				this.scene.add(configurationObject);

				if (updateCameraPosition) {
					// Camera change will only be correct if all objects are rendered
					this.handleCameraChange();
				}
			});
		}
	}

	animate() {
		// Pauses the rendering when the browser is on another tab, not wasting any resources
		this.renderingId = requestAnimationFrame(this.animate.bind(this));

		this.camera.updateProjectionMatrix();

		// Render the scene
		this.renderer.render(this.scene, this.camera);

		let cameraPosition = new Vector3();
		this.camera.getWorldPosition(cameraPosition);
	}

	resizeCanvas(width, height) {
		if (this.renderer != null && this.camera != null && this.canvas.current != null) {
			this.renderer.setSize(width, height);
			this.renderer.setPixelRatio(window.devicePixelRatio);

			this.camera.aspect = width / height;
			this.camera.updateProjectionMatrix();
		}
	}

	handleCameraChange(angle) {
		if (!this.props.currentConfiguration) return;
		if (!this.props.currentPiece) return;

		let cameraAngle;

		if (angle) cameraAngle = angle;
		else {
			const presets = UPRIGHT_PRESETS.concat(VERTICAL_DISPLAYED_PRESETS);

			if (presets.includes(this.props.currentConfiguration.options.preset)) cameraAngle = FRONT;
			else cameraAngle = TOP;
		}

		const currentPiece = this.props.currentPiece;
		const cameraPosition = getCameraPositionByAspectForObject(
			cameraAngle,
			currentPiece,
			this.props.currentConfiguration,
			this.scene,
		);
		const positionToLookAt = getPositionToPointCameraAt(currentPiece, this.props.currentConfiguration.options.preset);
		if (this.state.view !== cameraAngle) this.setState({ view: cameraAngle });

		this.camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
		this.camera.zoom = 1;
		this.controls.target.set(positionToLookAt.x, positionToLookAt.y, positionToLookAt.z);
		this.controls.update();
	}

	changeCanvasAspect(aspect) {
		if (aspect === Values.THREE_DIMENSIONAL) {
			this.controls.enablePan = true;
			this.controls.enableRotate = true;

			this.setState(
				{
					threeDimensionalIsEnabled: true,
					perspectiveIcon: Values.THREE_DIMENSIONAL,
				},
				this.refreshCanvas,
			);
		} else {
			this.controls.enablePan = false;
			this.controls.enableRotate = false;

			this.handleCameraChange();

			this.setState(
				{
					threeDimensionalIsEnabled: false,
					perspectiveIcon: Values.TWO_DIMENSIONAL,
				},
				this.refreshCanvas,
			);
		}
	}

	getPiecesToRenderDisjoint() {
		let piecesToRender = [];
		let currentPiece = this.props.currentConfiguration.pieces.find(piece => piece.id === this.props.currentPiece?.id);

		if (!currentPiece) return piecesToRender;

		// Get the pieces that have connected objects
		// Pieces with no connected objects cannot be drawn in a set of pieces
		let pieces = this.props.currentConfiguration.pieces.filter(piece => piece.connectedObjects.length > 0);

		const set = new DisjointSet(
			pieces.length, // Size of disjoint set
			function (s1, s2, edge) {
				return {
					maxWeight: Math.max(s1?.maxWeight, s2?.maxWeight, edge.weight),
				};
			},
			{ maxWeight: 0 }, // Initial value for subset properties.
		);

		pieces.forEach(piece => {
			if (piece.connectedObjects.length > 0) {
				let pieceIndex = pieces.findIndex(p => p.id === piece.id);
				let connectedPieceIndex = pieces.findIndex(p => p.id === piece.connectedObjects[0].id);

				set.union(pieceIndex, connectedPieceIndex, { weight: 1 });
			}
		});

		const subsets = set.subsets();
		const indexesToRender = subsets.filter(subset =>
			subset.includes(pieces.findIndex(p => p.id === currentPiece.id)),
		)[0];

		if (indexesToRender && indexesToRender.length > 0) {
			pieces.forEach(piece => {
				const pieceIndex = pieces.findIndex(p => p.id === piece.id);
				if (indexesToRender.includes(pieceIndex)) piecesToRender.push(piece);
			});
		} else {
			piecesToRender.push(currentPiece);
		}

		return piecesToRender;
	}

	//endregion

	initializeCurrentConfiguration(configurations) {
		let currentConfiguration = null;

		if (this.props.currentConfiguration) {
			currentConfiguration = configurations.find(
				configuration => configuration.id === this.props.currentConfiguration.id,
			);
		} else {
			// Set the currentConfiguration based on the id in the route
			configurations.forEach(configuration => {
				if (configuration.id === parseInt(this.props.match.params.configurationId)) {
					currentConfiguration = configuration;
				}
			});

			// Set the first configuration as selected, when the configuration from the url is not found
			if (!currentConfiguration && configurations.length > 0) {
				currentConfiguration = configurations[0];
			}
		}

		return currentConfiguration;
	}

	initializeCurrentPiece(currentPiece, pieces) {
		// Set the current piece
		if (pieces.length > 0) {
			const currentPieceBelongsToConfiguration = pieces.find(piece => piece.id === currentPiece?.id) != null;

			if (!currentPieceBelongsToConfiguration) {
				currentPiece = pieces[0];
			}
		}

		return currentPiece;
	}

	//region API calls
	getCurrentOffer() {
		this.props.setWindowIsLoading(true);
		this.props.setPriceIsLoading(true);

		this.offerService
			.getByIdComplete(this.props.match.params.id, this.props.priceType)
			.then(response => {
				if (response.success) {
					this.configurableReducerHelper.updateConfigurable(response.data);

					return response.data;
				} else {
					this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'getOfferFailed'));
					history.push('/offers');
				}
			})
			.then(offer => {
				if (offer == null) return;
				if (offer.configurations.length === 0) return;

				const currentConfiguration = this.initializeCurrentConfiguration(offer.configurations);
				const currentPiece = this.initializeCurrentPiece(this.props.currentPiece, currentConfiguration.pieces);
				this.props.setCurrentPiece(currentPiece);

				this.configurableReducerHelper.updateConfiguration(currentConfiguration);

				// Set pixel ratio on initial load
				this.renderer.setPixelRatio(window.devicePixelRatio);
			})
			.catch(() => {
				this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'getOfferFailed'));
			})
			.finally(() => {
				this.props.setWindowIsLoading(false);
				this.props.setPriceIsLoading(false);
			});
	}

	getCurrentOrder() {
		const orderId = parseInt(this.props.match.params.id);
		this.props.setWindowIsLoading(true);
		this.props.setPriceIsLoading(true);

		const priceType = this.props.priceType || DISCOUNTED;

		this.orderService
			.getById(orderId, priceType)
			.then(response => {
				if (response.success) {
					const currentConfiguration = this.initializeCurrentConfiguration(response.data.configurations);
					const currentPiece = this.initializeCurrentPiece(this.props.currentPiece, currentConfiguration.pieces);

					this.props.setCurrentOrder(response.data);
					this.props.setCurrentConfiguration(currentConfiguration);
					this.props.setCurrentPiece(currentPiece);

					// Set pixel ratio on initial load
					this.renderer.setPixelRatio(window.devicePixelRatio);
				} else {
					throw Error(response.message);
				}
			})
			.catch(error => {
				this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'fetchCurrentOrderFailed'));
				throw error;
			})
			.finally(() => {
				this.props.setWindowIsLoading(false);
				this.props.setPriceIsLoading(false);
			});
	}

	getConfigurationPrice() {
		if (this.props.canEdit) return;

		if (!this.props.currentOffer || !this.props.currentConfiguration) return;

		this.props.setPriceIsLoading(true);

		this.configurationService
			.getPrice(this.props.currentOffer.id, this.props.currentConfiguration.id, this.props.priceType)
			.then(response => {
				if (response.success) {
					let tempConfiguration = this.props.currentConfiguration;
					tempConfiguration.price = response.data;

					this.configurableReducerHelper.updateConfiguration(tempConfiguration);
				}
			})
			.catch(() => {
				this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'fetchingPriceFailed'));
			})
			.finally(() => this.props.setPriceIsLoading(false));
	}

	updateExpiryDate() {
		this.props.setWindowIsLoading(true);
		this.offerService
			.updateExpiryDate(this.props.currentOffer.id)
			.then(() => {
				history.goBack();
			})
			.catch(e => {
				captureException(e);

				this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'updateOfferExpiryDateFailedMessage'));
			})
			.finally(() => this.props.setWindowIsLoading(false));
	}

	async updateOrder() {
		if (this.props.currentOrder == null) return;

		let imagesCreator = new ImageCreator(this.props.currentOrder.configurations);

		this.props.setWindowIsLoading(true);

		const images = await imagesCreator.getImages(true);
		const order = await this.orderService.recalculatePrices(this.props.currentOrder.id, images, this.props.priceType);
		this.configurableReducerHelper.updateConfigurable(order);

		this.props.setConfiguratorStatus(EDITING_ORDER_OFF);

		this.props.setWindowIsLoading(false);
	}

	//endregion

	setCurrentConfiguration(configuration) {
		this.props.setCurrentConfiguration(configuration);

		if (configuration.pieces.length > 0) {
			this.props.setCurrentPiece(configuration.pieces[0]);
		}
	}

	//region Render methods

	closeConnectPieceModal(shouldUpdateCanvas) {
		this.props.setActiveOperationModal();
		if (shouldUpdateCanvas) this.refreshCanvas();
	}

	closeConfigurationModal(shouldRefreshCanvas) {
		if (shouldRefreshCanvas) this.refreshCanvas();

		this.setState({
			configurationModalIsActive: false,
		});
	}

	openConfigurationModal(isEditing, configuration) {
		if (isEditing && configuration != null) {
			this.props.setCurrentConfiguration(configuration);
		}

		this.setState({
			configurationModalIsActive: true,
			isEditingConfiguration: isEditing,
		});
	}

	duplicateConfiguration(configuration) {
		this.props.setWindowIsLoading(true);

		this.configurationService
			.duplicate(configuration)
			.then(response => {
				if (response.success) {
					this.props.addAlertMessage(SUCCESS, this.props.t(this.prefix + 'duplicateConfigurationSuccess'));

					const tempOffer = this.props.currentOffer;
					tempOffer.configurations.push(response.data);

					this.configurableReducerHelper.updateConfigurable(tempOffer);
				}
			})
			.catch(e => {
				captureException(e);

				this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'duplicateConfigurationFailed'));
			})
			.finally(() => this.props.setWindowIsLoading(false));
	}

	openConfigurationDeleteModal(id) {
		this.setState({
			configurationIdToDelete: id,
			configurationDeleteModalIsActive: true,
		});
	}

	closeConfigurationDeleteModal(hasAccepted) {
		if (hasAccepted && this.state.configurationIdToDelete) {
			this.props.setWindowIsLoading(true);

			this.configurationService
				.delete(this.state.configurationIdToDelete)
				.then(success => {
					if (success) {
						this.props.addAlertMessage(SUCCESS, this.props.t(this.prefix + 'deleteConfigurationSuccess'));

						let tempOffer = this.props.currentOffer;

						tempOffer.configurations = tempOffer.configurations.filter(
							configuration => configuration.id !== this.state.configurationIdToDelete,
						);

						this.props.setCurrentOffer(tempOffer);

						let newCurrentConfiguration = null;

						if (
							this.props.currentConfiguration.id === this.state.configurationIdToDelete &&
							tempOffer.configurations.length > 0
						) {
							newCurrentConfiguration = tempOffer.configurations[0];
						}

						this.props.setCurrentConfiguration(newCurrentConfiguration);
					} else {
						this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'deleteConfigurationFailed'));
					}
				})
				.catch(() => {
					this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'deleteConfigurationFailed'));
				})
				.finally(() => {
					this.setState({ configurationIdToDelete: 0 });
					this.props.setWindowIsLoading(false);
				});
		}

		this.setState({ configurationDeleteModalIsActive: false });
	}

	closeConfirmationModal(hasAccepted) {
		if (hasAccepted) {
			this.props.confirmationModal.onAccept();
		} else if (this.props.confirmationModal.onClose) {
			this.props.confirmationModal.onClose();
		}

		this.props.setConfirmationModal(null);
	}

	openDeleteOfferModal() {
		this.setState({ deleteOfferModalIsActive: true });
	}

	closeDeleteOfferModal(hasAccepted) {
		if (hasAccepted) {
			this.props.setWindowIsLoading(true);

			this.offerService
				.deleteOffer(this.props.currentOffer.id)
				.then(success => {
					if (success) {
						this.props.addAlertMessage(SUCCESS, this.props.t(this.prefix + 'deleteOfferSuccess'));
						this.props.setCurrentOffer(null);
						history.push('/offers');
					} else {
						this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'deleteOfferFailed'));
					}
				})
				.catch(() => {
					this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'deleteOfferFailed'));
				})
				.finally(() => this.props.setWindowIsLoading(false));
		}

		this.setState({
			deleteOfferModalIsActive: false,
		});
	}

	closeFinishedSidesModal(finishedSides, waterlists) {
		if (!finishedSides && !waterlists) {
			this.props.setActiveOperationModal();
			return;
		}

		let existingFinishedSides = this.props.currentPiece.getFinishedSides;
		let existingWaterlists = this.props.currentPiece.waterlists;
		let promises = [];

		// Sort alphabetically so the comparing of the arrays is easier
		const sortFunction = (a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		};

		existingFinishedSides.sort(sortFunction);
		finishedSides.sort(sortFunction);

		existingWaterlists.sort(sortFunction);
		waterlists.sort(sortFunction);

		let finishedSideArraysAreEqual = _.isEqual(existingFinishedSides, finishedSides, (a, b) => {
			let isEqual = true;

			if (a.name !== b.name || a.type !== b.type) isEqual = false;

			return isEqual;
		});

		let waterlistArraysAreEqual = _.isEqual(existingWaterlists, waterlists, (a, b) => {
			let isEqual = true;

			if (a.name !== b.name || a.type !== b.type) isEqual = false;

			return isEqual;
		});

		if (!finishedSideArraysAreEqual) {
			const activePiece = this.props.currentPiece;
			promises.push(
				this.pieceService
					.setFinishedSides(this.props.currentConfiguration.id, activePiece, finishedSides)
					.then(response => {
						if (response.success) {
							let tempConfiguration = this.props.currentConfiguration;

							tempConfiguration.pieces.forEach(piece => {
								if (piece.id === activePiece.id) {
									piece.operations = response.data;
								}
							});

							this.configurableReducerHelper.updateConfiguration(tempConfiguration);
							this.props.setShouldUpdatePrice(true);
						} else {
							this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'setFinishedSidesFailed'));
						}
					})
					.catch(error => {
						this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'setFinishedSidesFailed'));
						throw error;
					}),
			);
		}

		if (!waterlistArraysAreEqual) {
			promises.push(
				this.pieceService
					.setWaterlists(this.props.currentConfiguration.id, this.props.currentPiece, waterlists)
					.then(response => {
						let tempConfiguration = this.props.currentConfiguration;

						tempConfiguration.pieces = tempConfiguration.pieces.map(piece => {
							if (this.props.currentPiece.id === piece.id) {
								piece.operations = response.data.map(operation => operation);
							}

							return piece;
						});

						this.configurableReducerHelper.updateConfiguration(tempConfiguration);
					})
					.catch(error => {
						this.props.addAlertMessage(DANGER, this.props.t(this.prefix + 'setWaterlistsFailed'));
						throw error;
					}),
			);
		}

		Promise.all(promises)
			.then(() => {
				this.refreshCanvas();
				this.props.setShouldUpdatePrice(true);
			})
			.catch(error => {
				throw error;
			});

		this.props.setActiveOperationModal();
	}

	closeOperationModal(shouldRefreshCanvas) {
		this.props.setActiveOperationModal();
		this.props.setCurrentOperation(null);
		if (shouldRefreshCanvas) {
			console.log('triggered');
			console.log(shouldRefreshCanvas);
			this.refreshCanvas();
		}
	}

	closeInfoModal() {
		this.props.setActiveInfoModal(false, '');
	}

	closeDividePieceModal(shouldRefresh) {
		if (shouldRefresh) {
			this.refreshCanvas();
			this.props.setShouldUpdatePrice(true);
		}

		this.props.setActiveOperationModal();
	}

	//endregion

	render() {
		const { t } = this.props;

		const getSummaryElements = configuration => {
			const options = configuration.options;

			const getIcon = name => {
				try {
					return this.images('./' + name + '.jpg');
				} catch (e) {
					return icon50;
				}
			};

			const decorIcon = options.decor === Values.EXTERIOR ? exteriorIcon : interiorIcon;
			const presetIcon = icon50;
			const subPresetIcon = options.isConfiguredForStock() ? stockIcon : null;
			const materialIcon = getIcon(options.material.toLowerCase());
			const colorIcon = getIcon(options.materialColor.toLowerCase());
			const typeIcon = getIcon(options.type.toLowerCase());

			return (
				<div className="list__item__content__specs">
					<img
						data-cy={`configurator-sideBarListItem-decor-${options.decor.toLowerCase()}`}
						data-tip={t('constants.values.' + options.decor)}
						alt="Summary of options"
						src={decorIcon}
					/>

					{
						// Only render when subPresetIcon will not be
						!subPresetIcon ? (
							<img
								data-cy={`configurator-sideBarListItem-preset-${options.preset.toLowerCase()}`}
								data-tip={t('constants.presets.' + options.preset)}
								alt="Summary of options"
								src={presetIcon}
							/>
						) : null
					}

					{subPresetIcon ? (
						<img
							data-cy={`configurator-sideBarListItem-subPreset-${options.subPreset.toLowerCase()}`}
							data-tip={t('constants.presets.' + options.subPreset)}
							alt="Summary of options"
							src={subPresetIcon}
						/>
					) : null}

					<img
						data-cy={`configurator-sideBarListItem-material-${options.material.toLowerCase()}`}
						data-tip={t('constants.materials.' + options.material)}
						alt="Summary of options"
						src={materialIcon}
					/>

					<img
						data-cy={`configurator-sideBarListItem-materialColor-${options.materialColor.toLowerCase()}`}
						data-tip={t('constants.materialColors.' + options.materialColor)}
						alt="Summary of options"
						src={colorIcon}
					/>
					{configuration.shouldAddTypeInSummary() && (
						<img
							data-cy={`configurator-sideBarListItem-${options.type.toLowerCase()}`}
							data-tip={t('constants.types.' + options.type)}
							alt="Summary of options"
							src={typeIcon}
						/>
					)}

					<small data-cy={`configurator-sideBarListItem-height`} data-tip={t('constants.dimensions.height')}>
						{options.height + ' cm'}
					</small>

					<ReactTooltip />
				</div>
			);
		};

		const renderSideBarItems = () => {
			const configurations =
				this.props.match.params.type === OFFER
					? this.props.currentOffer?.configurations
					: this.props.currentOrder?.configurations;
			if (!configurations) return;

			if (configurations.length < 1) {
				return <EmptySidebarListItem content={this.props.t(this.prefix + 'noConfigurationsListItem')} />;
			}

			let listItems = configurations.filter(configuration =>
				configuration.name.toLowerCase().includes(this.state.searchKeyword.toLowerCase()),
			);

			if (listItems.length > 0) {
				return listItems.map(configuration => {
					const pieceCount = (function () {
						let count = 0;

						configuration.pieces.forEach(piece => {
							count += piece.amount;
						});

						return count;
					})();

					let subText =
						pieceCount === 1
							? pieceCount + ' ' + this.props.t(this.prefix + 'piece')
							: pieceCount + ' ' + this.props.t(this.prefix + 'pieces');

					return (
						<SidebarListItem
							dataCy={`configurator-sideBarListItem-${configuration.id}`}
							key={configuration.id}
							content={configuration.name}
							subText={subText}
							active={this.props.currentConfiguration?.id === configuration.id}
							variant={LIST_ITEM.CONFIG}
							onClick={() => this.setCurrentConfiguration(configuration)}
							summary={getSummaryElements(configuration)}
						>
							<ConfigurationActionsDropdown
								onDuplicate={() => this.duplicateConfiguration(configuration)}
								onUpdate={() => this.openConfigurationModal(true, configuration)}
								onDelete={() => this.openConfigurationDeleteModal(configuration.id)}
							/>
						</SidebarListItem>
					);
				});
			} else {
				return <EmptySidebarListItem content={this.props.t(this.prefix + 'noMatchWithSearchInputListItem')} />;
			}
		};

		const renderPage = () => {
			return (
				<AppHolder>
					{this.props.windowIsLoading ? <LoadingMessage variant="overlay" /> : null}

					<ConfigurationModal
						isActive={this.state.configurationModalIsActive}
						onClose={shouldUpdateCanvas => this.closeConfigurationModal(shouldUpdateCanvas)}
						isEditing={this.state.isEditingConfiguration}
						configuration={this.props.currentConfiguration}
					/>

					<ConfirmationModal
						isActive={this.state.deleteOfferModalIsActive}
						onClose={hasAccepted => this.closeDeleteOfferModal(hasAccepted)}
						content={t(this.prefix + 'deleteOfferConfirmation')}
					/>

					<ConfirmationModal
						isActive={this.state.configurationDeleteModalIsActive}
						onClose={hasAccepted => this.closeConfigurationDeleteModal(hasAccepted)}
						content={t(this.prefix + 'deleteConfigurationConfirmation')}
					/>

					<ConfirmationModal
						isActive={this.props.confirmationModal.isActive}
						onClose={hasAccepted => this.closeConfirmationModal(hasAccepted)}
						content={this.props.confirmationModal.content}
					/>

					<InfoModal
						isActive={this.props.activeInfoModal?.isActive}
						onClose={() => this.closeInfoModal()}
						content={this.props.activeInfoModal?.content}
					/>

					<OperationModal
						currentPiece={this.props.currentPiece}
						activeOperationModal={this.props.activeOperationModal}
						closeOperationModal={shouldRefreshCanvas => this.closeOperationModal(shouldRefreshCanvas)}
						closeConnectPieceModal={shouldRefreshCanvas => this.closeConnectPieceModal(shouldRefreshCanvas)}
						closeDividePieceModal={shouldRefreshCanvas => this.closeDividePieceModal(shouldRefreshCanvas)}
						closeFinishedSidesModal={(finishedSides, waterlists) =>
							this.closeFinishedSidesModal(finishedSides, waterlists)
						}
					/>

					<NavigationBar />

					<SideBar
						dataCy="configurator-sideBar"
						onReturnClick={history.goBack}
						disabled={this.props.windowIsLoading || this.props.priceIsLoading}
						title={this.props.t(this.prefix + 'sideBarTitle')}
					>
						<SearchBar onInput={input => this.setState({ searchKeyword: input })} />

						{this.props.windowIsLoading || this.props.listIsLoading ? (
							<LoadingMessage />
						) : (
							<>
								<SidebarList>{renderSideBarItems()}</SidebarList>
								{this.props.canEdit ? (
									<div className="p-2">
										<span
											data-cy="configurator-sideBar-addConfiguration"
											className="button button--outline w-100"
											onClick={() => this.openConfigurationModal()}
										>
											+ {this.props.t(this.prefix + 'addConfigurationSidebar')}
										</span>
									</div>
								) : null}
							</>
						)}
					</SideBar>

					<AppContent>
						<ConfiguratorAppHeader
							openConfigurationModal={() => this.openConfigurationModal(false)}
							openDeleteOfferModal={() => this.openDeleteOfferModal()}
							updateExpiryDate={() => this.updateExpiryDate()}
						/>

						<div className="configuration">
							<Canvas3D
								reference={this.canvas}
								activeView={this.state.view}
								onResize={(width, height) => this.resizeCanvas(width, height)}
								onAspectChange={aspect => this.changeCanvasAspect(aspect)}
								onViewChange={view => this.handleCameraChange(view)}
							/>

							<PieceList pieces={this.props.currentConfiguration?.pieces} refreshCanvas={() => this.refreshCanvas()} />
						</div>
					</AppContent>

					<MessageHolder messages={this.props.alertMessages} onClose={index => this.props.removeAlertMessage(index)} />
				</AppHolder>
			);
		};

		return <ConfiguratorContext.Provider value={null}>{renderPage()}</ConfiguratorContext.Provider>;
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.generalReducer.currentUser,
		currentOffer: state.offerReducer.currentOffer,
		currentOrder: state.orderReducer.currentOrder,
		currentConfiguration: state.offerReducer.currentConfiguration,
		currentPiece: state.offerReducer.currentPiece,
		currentOperation: state.offerReducer.currentOperation,
		alertMessages: state.generalReducer.alertMessages,
		windowIsLoading: state.generalReducer.windowIsLoading,
		listIsLoading: state.generalReducer.listIsLoading,
		priceIsLoading: state.generalReducer.priceIsLoading,
		activeOperationModal: state.generalReducer.activeOperationModal,
		activeInfoModal: state.generalReducer.activeInfoModal,
		shouldUpdatePrice: state.generalReducer.shouldUpdatePrice,
		priceType: state.generalReducer.priceType,

		canEdit: state.generalReducer.canEdit,
		confirmationModal: state.generalReducer.confirmationModal,

		settings: state.generalReducer.settings,

		configuratorStatus: state.configuratorReducer.status,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setCurrentOffer: offer => dispatch(OfferActions.setCurrentOffer(offer)),
		setCurrentOrder: order => dispatch(setCurrentOrder(order)),
		setCurrentConfiguration: currentConfiguration =>
			dispatch(OfferActions.setCurrentConfiguration(currentConfiguration)),
		setCurrentPiece: currentPiece => dispatch(OfferActions.setCurrentPiece(currentPiece)),
		setCurrentOperation: currentOperation => dispatch(OfferActions.setCurrentOperation(currentOperation)),
		addAlertMessage: (variant, content) => dispatch(GeneralActions.addAlertMessage(variant, content)),
		removeAlertMessage: index => dispatch(GeneralActions.removeAlertMessage(index)),

		setWindowIsLoading: isLoading => dispatch(GeneralActions.setWindowIsLoading(isLoading)),
		setScreenIsLoading: isLoading => dispatch(GeneralActions.setScreenIsLoading(isLoading)),
		setListIsLoading: isLoading => dispatch(GeneralActions.setListIsLoading(isLoading)),
		setPriceIsLoading: isLoading => dispatch(GeneralActions.setPriceIsLoading(isLoading)),

		setActiveOperationModal: type => dispatch(GeneralActions.setActiveOperationModal(type)),
		setActiveInfoModal: (isActive, content) => dispatch(GeneralActions.setActiveInfoModal(isActive, content)),

		setShouldUpdatePrice: shouldUpdate => dispatch(GeneralActions.setShouldUpdatePrice(shouldUpdate)),
		setCanEdit: canEdit => dispatch(GeneralActions.setCanEdit(canEdit)),
		setConfirmationModal: data => dispatch(GeneralActions.setConfirmationModal(data)),
		setConfiguratorStatus: status => dispatch(ConfiguratorActions.setConfiguratorStatus(status)),
		setConfigurableType: configurableType => dispatch(ConfiguratorActions.setConfigurableType(configurableType)),
	};
}

Configurator.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(TRANSLATION_NAMESPACE)(Configurator));
