import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { LIST_ITEM } from '../../../constants/Variants';
import { useSelector } from 'react-redux';
import { OrderHandledButton } from '../../shared/OrderHandledButton';
import { ConfigurationActionsDropdown } from '../../pages/configurator/ConfigurationActionsDropdown';
import { OrderStatus } from '../../pages/orders/OrderStatus';
import { OrderItemActionsDropdown } from '../../pages/orders/OrderItemActionsDropdown';
import { AdsolutBadge } from '../../shared/AdsolutBadge';

export const SidebarListItem = props => {
	const canEdit = useSelector(state => state.generalReducer.canEdit);

	const getChildren = () => {
		return props.children && props.children.length ? props.children.filter(c => c !== undefined) : [];
	};

	const getStyling = () => {
		let stylingClass = ' w-100 list__item';

		switch (props.variant) {
			case LIST_ITEM.OFFER:
				stylingClass += ' list__item--offer';
				break;
			case LIST_ITEM.CONFIG:
				stylingClass += ' list__item--config';
				break;
			case LIST_ITEM.ORDER:
				// TODO Remove this styling?
				// stylingClass += ' list__item--order';
				break;
			case LIST_ITEM.ADD:
				stylingClass += ' list__item--add';
				break;
			default:
				break;
		}

		if (props.active) stylingClass += ' list__item--active';

		return stylingClass;
	};

	const renderChildren = () => {
		let elements;
		const children = Children.toArray(props.children);

		switch (props.variant) {
			case LIST_ITEM.CONFIG:
				if (canEdit && children) {
					elements = children.find(child => child.type === ConfigurationActionsDropdown);
				}
				break;
			case LIST_ITEM.ORDER:
				const orderStatus = children.find(child => child.type === OrderStatus);
				const dropdown = children.find(child => child.type === OrderItemActionsDropdown);

				elements = (
					<>
						{orderStatus}
						{dropdown}
					</>
				);
				break;
			default:
				elements = null;
				break;
		}

		return elements;
	};

	const renderContent = () => {
		if (props.id) {
			return <span>{`ID: ${props.id} - ${props.content}`}</span>;
		}

		return <span>{props.content}</span>;
	};

	const renderAdsolutBadge = () => {
		const children = getChildren();

		if (!children || !children.length) return;

		const badgeIndex = children.findIndex(child => child.type === AdsolutBadge);

		if (badgeIndex !== -1) {
			return <div className="m-r-2">{children[badgeIndex]}</div>;
		}
	};

	const renderOrderHandledButton = () => {
		const children = getChildren();

		if (!children || !children.length) return;

		const buttonIndex = children.findIndex(child => child.type === OrderHandledButton);

		if (buttonIndex !== -1) {
			return <div className="m-r-2">{children[buttonIndex]}</div>;
		}
	};

	return (
		<div data-cy={props.dataCy} className={getStyling()} onClick={() => props.onClick()}>
			{renderOrderHandledButton()}

			{renderAdsolutBadge()}

			<div className="list__item__content">
				{renderContent()}
				<small data-cy={'sideBarListItem-subtext'}>{props.subText}</small>
				{props.summary ?? null}
			</div>

			{renderChildren()}
		</div>
	);
};

SidebarListItem.propTypes = {
	id: PropTypes.number,
	content: PropTypes.string.isRequired,
	subText: PropTypes.string,
	active: PropTypes.bool.isRequired,
	onClick: PropTypes.func,
	variant: PropTypes.string,
	summary: PropTypes.any,
	cypressTestName: PropTypes.string,
	dataCy: PropTypes.string,
};

SidebarListItem.defaultProps = {
	active: false,
	icon: '',
	summary: '',
	subText: '',
};
