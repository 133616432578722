import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Piece } from '../../../classes/models/Piece';
import { useDispatch, useSelector } from 'react-redux';
import { PieceListItemHeader } from './PieceListItemHeader';
import { PieceListItemDimensions } from './PieceListItemDimensions';
import { OperationList } from './OperationList';
import { BAR_WIDTH, EXTRUSION, FRONT_HEIGHT, HEIGHT_BACK_SIDE, LENGTH, WIDTH } from '../../../constants/Dimensions';
import { PieceListItemActions } from './PieceListItemActions';
import * as OperationTypes from '../../../constants/OperationTypes';
import DimensionValidator from '../../../classes/helpers/DimensionValidator';
import { parseNumber } from '../../../classes/helpers/StringHelper';
import { PIECE } from '../../../constants/PieceActionTypes';
import { dividePieceInParts } from '../../../classes/helpers/PieceDivisionHelper';
import { Message } from '../../messages/Message';
import { WARNING } from '../../../constants/Variants';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { TOO_HIGH, TOO_LOW } from '../../../constants/ValidationErrors';
import { INVERTED_DIMENSION_TYPES, TYPE_3, TYPE_4 } from '../../../constants/ObjectTypes';
import { setConfiguratorUnsavedDimension } from '../../../actions/GeneralActions';
import { getOperationsToRelocate } from '../../../classes/helpers/OperationHelper';

export const PieceListItem = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const validationPrefix = 'validation.';
	const dimensionsPrefix = 'constants.dimensions.';
	const operationsPrefix = 'constants.operationTypes.';

	const [isCollapsed, setIsCollapsed] = useState(true);
	const [warningMessage, setWarningMessage] = useState('');

	const { currentConfiguration, currentPiece } = useSelector(state => state.offerReducer);
	const { canEdit, configuratorUnsavedDimension } = useSelector(state => state.generalReducer);

	const dispatch = useDispatch();

	const validator = new DimensionValidator();

	useEffect(() => {
		setIsCollapsed(currentPiece?.id !== props.piece?.id);
	}, [currentPiece]);

	const getStyling = () => {
		const defaultStyling = 'configuration__objects__item';

		if (currentPiece?.id === props.piece?.id) return defaultStyling + ' active';

		return defaultStyling;
	};

	const getDimensionName = dimensionName => {
		if (INVERTED_DIMENSION_TYPES.includes(currentConfiguration?.options.type)) {
			if (dimensionName === LENGTH) dimensionName = WIDTH;
			else if (dimensionName === WIDTH) dimensionName = LENGTH;
		}

		return dimensionName;
	};

	const valueIsChanged = (dimensionName, value) => {
		if ([TYPE_3, TYPE_4].includes(currentConfiguration?.options.type) && dimensionName === HEIGHT_BACK_SIDE) {
			dimensionName = EXTRUSION;
			value -= props.piece.dimensions.height;
		}

		return parseNumber(props.piece.dimensions[dimensionName]) !== value;
	};

	const handleValidationError = (validationError, dimensionName, pieceDimensions) => {
		const dimensionLabel = getDimensionName(dimensionName);

		if (validationError === TOO_LOW) {
			setWarningMessage(
				t(validationPrefix + 'dimensionValueTooLow', {
					dimensionName: t(dimensionsPrefix + dimensionLabel),
					value: validator.getMinValue(dimensionName, currentConfiguration.options),
				}),
			);
		} else if (validationError === TOO_HIGH) {
			setWarningMessage(
				t(validationPrefix + 'dimensionValueTooHigh', {
					dimensionName: t(dimensionsPrefix + dimensionLabel),
					value: validator.getMaxValue(dimensionName, currentConfiguration.options, pieceDimensions),
				}),
			);
		} else {
			// INVALID_VALUE
			setWarningMessage(
				dimensionName === LENGTH
					? t(validationPrefix + 'invalidValueStockLength')
					: t(validationPrefix + 'invalidValueStockWidth'),
			);
		}
	};

	const handleOperationsToRelocate = operationsToRelocate => {
		const translatedOperations = operationsToRelocate
			.map(operation => t(operationsPrefix + operation.type).toLowerCase())
			.join(', ');

		setWarningMessage(t(validationPrefix + 'relocateOperationsMessage', { operations: translatedOperations }));
	};

	const updateDimension = (dimensionName, value) => {
		value = parseNumber(value);

		if (isNaN(value) || value < 0) return;

		let piece = Object.assign(new Piece(), props.piece);

		let validationError = validator.validateDimension(
			dimensionName,
			value,
			currentConfiguration.options,
			piece.dimensions,
		);

		if (!validationError && currentConfiguration?.options.isConfiguredForStock()) {
			validationError = validator.validateStockDimension(dimensionName, value);
		}

		if (!validationError) {
			const operationsToRelocate = getOperationsToRelocate(piece, dimensionName, value);

			if (operationsToRelocate.length) {
				handleOperationsToRelocate(operationsToRelocate);
				dispatch(setConfiguratorUnsavedDimension(dimensionName));
				return;
			}
		}

		if (validationError) {
			handleValidationError(validationError, dimensionName, piece.dimensions);
			dispatch(setConfiguratorUnsavedDimension(dimensionName));
			return;
		} else {
			if (warningMessage) setWarningMessage('');
			if (configuratorUnsavedDimension) dispatch(setConfiguratorUnsavedDimension());
		}

		if (!valueIsChanged(dimensionName, value)) return;

		if (dimensionName === LENGTH || dimensionName === WIDTH) {
			// There are setters for length and width for some extra functionality when these dimensions change
			piece[dimensionName] = value;

			piece.parts = dividePieceInParts(
				piece.dimensions.length,
				piece.dimensions.width,
				piece.dimensions.height,
				currentConfiguration.options.type,
				currentConfiguration.options.isConfiguredForStock() ? 1 : null,
			);
		} else {
			piece.dimensions = handlePieceDimensionChange(piece.dimensions, dimensionName, value);
		}

		props.updatePiece(piece);
	};

	const handlePieceDimensionChange = (pieceDimensions, dimensionName, value) => {
		// When there is no bar (bar width of 0) then the extrusion should also be 0
		if (dimensionName === BAR_WIDTH && value === 0) {
			pieceDimensions.extrusion = 0;
		}

		// Make sure the extrusion cannot be incremented when the barWidth is 0
		if (dimensionName === EXTRUSION && value > 0 && pieceDimensions.barWidth === 0) {
			value = 0;
		}

		// Make sure the front height is lower or the same as the extrusion
		if (dimensionName === EXTRUSION && pieceDimensions.height - value < pieceDimensions.frontHeight) {
			pieceDimensions.frontHeight = pieceDimensions.height - value;
		}

		// Make sure the front height cannot be higher than the extrusion
		if (dimensionName === FRONT_HEIGHT && value > pieceDimensions.height - pieceDimensions.extrusion) {
			pieceDimensions.extrusion = pieceDimensions.height - pieceDimensions.frontHeight;
		}

		if (dimensionName === FRONT_HEIGHT && pieceDimensions.height - value < pieceDimensions.extrusion) {
			pieceDimensions.extrusion = pieceDimensions.height - value;
		}

		if (dimensionName === HEIGHT_BACK_SIDE) {
			pieceDimensions.extrusion = value - pieceDimensions.height;
		}

		pieceDimensions[dimensionName] = value;

		return pieceDimensions;
	};

	const getDisabledActions = () => {
		let disabledActions = [];

		if (props.piece.getAvailableSidesToConnectPieces().length < 1) {
			disabledActions.push(OperationTypes.COUPE);
			disabledActions.push(PIECE);
		}

		return disabledActions;
	};

	const getOnDeleteClickHandler = () => {
		if (props.piece.connectedObjects.length < 2) {
			return () => props.onDeleteClick();
		}

		return null;
	};

	return (
		<div ref={props.reference} className={getStyling()} data-cy={props.dataCy}>
			<PieceListItemHeader
				dataCy={`${props.dataCy}-header`}
				piece={props.piece}
				selected={props.piece.id === currentPiece?.id}
				collapsed={isCollapsed}
				duplicatePiece={() => props.duplicatePiece()}
				updateAmount={amount => props.updateAmount(amount)}
				onEditClick={() => props.onEditNameClick()}
				onDeleteClick={getOnDeleteClickHandler()}
				onSelect={() => props.onClick(props.piece)}
			/>

			{!isCollapsed && (
				<>
					<div data-cy={`${props.dataCy}-dimensions`}>
						<PieceListItemDimensions
							dataCy="dimensions"
							dimensions={props.piece.dimensions}
							pieceType={props.piece.type}
							onDimensionUpdate={(dimensionName, value) => updateDimension(dimensionName, value)}
							disabled={
								props.piece.connectedObjects.length > 1 ||
								!canEdit ||
								(props.piece.isAnchor && props.piece.connectedObjects.length > 0)
							}
						/>
					</div>

					{warningMessage && (
						<div className="p-l-2 p-r-2 m-b-2">
							<Message content={warningMessage} variant={WARNING} dataCy={'pieceListItem-warningMessage'} />
						</div>
					)}

					{!currentConfiguration?.options.isConfiguredForStock() && (
						<OperationList dataCy={`${props.dataCy}-operations`} piece={props.piece} />
					)}

					{!!(canEdit && !currentConfiguration?.options.isConfiguredForStock()) && (
						<PieceListItemActions piece={props.piece} disabledActions={getDisabledActions()} />
					)}
				</>
			)}
		</div>
	);
};

PieceListItem.propTypes = {
	reference: PropTypes.any,
	piece: PropTypes.instanceOf(Piece).isRequired,
	onEditNameClick: PropTypes.func.isRequired,
	duplicatePiece: PropTypes.func.isRequired,
	onDeleteClick: PropTypes.func.isRequired,
	updatePiece: PropTypes.func.isRequired,
	updateAmount: PropTypes.func.isRequired,
	onClick: PropTypes.func,
	dataCy: PropTypes.string,
};
