import * as React from 'react';
import PropTypes from 'prop-types';
import { ActionsDropdown } from '../../dropdowns/ActionsDropdown';
import { ActionsDropdownItem } from '../../dropdowns/items/ActionsDropdownItem';
import { DANGER } from '../../../constants/Variants';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { Address } from '../../../classes/models/Address';

export const AddressChooser = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const constantsPrefix = 'constants.countries.';
	const prefix = 'pages.order.addressChooser.';

	return (
		<>
			{props.addresses.map(address => (
				<label key={address.id} className="list__item list__item--bordered pointer" data-cy={props.dataCy}>
					<div className="list__item__check" onClick={() => props.onAddressClick(address)}>
						<input
							type="radio"
							name="address"
							checked={address.id === props.selectedAddress?.id}
							onChange={() => props.onAddressClick(address)}
						/>
					</div>
					<div className="list__item__content" onClick={() => props.onAddressClick(address)}>
						{address.street + ' ' + address.number}
						<br />
						{address.postalCode + ' ' + address.city + ', ' + t(constantsPrefix + address.country)}
					</div>
					<ActionsDropdown>
						<ActionsDropdownItem content={t(prefix + 'editAction')} onClick={() => props.onEdit(address)} />
						<ActionsDropdownItem
							variant={DANGER}
							content={t(prefix + 'deleteAction')}
							onClick={() => props.onDelete(address)}
						/>
					</ActionsDropdown>
				</label>
			))}

			<div className="list__item list__item--bordered pointer" onClick={() => props.onAddressAdd()}>
				<div className="list__item__icon">
					<i className="material-icons color--blue">add_circle</i>
				</div>
				<div className="list__item__content--blue">{t(prefix + 'addAddress')}</div>
			</div>
		</>
	);
};

AddressChooser.propTypes = {
	addresses: PropTypes.array.isRequired,
	selectedAddress: PropTypes.instanceOf(Address),
	onAddressClick: PropTypes.func.isRequired,
	onAddressAdd: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	dataCy: PropTypes.string,
};

AddressChooser.defaultProps = {
	selectedAddress: null,
};
