import * as React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { ModalHolder } from './ModalHolder';

function ConfirmationModal(props) {
	const prefix = 'modals.confirmationModal.';

	const { t } = useTranslation(TRANSLATION_NAMESPACE);

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal
				data-cy={'confirmation-modal'}
				show={props.isActive}
				onHide={props.onClose}
				animation={true}
				className="modal modal--prompt"
			>
				<Modal.Header className="modal-header" data-cy="confirmationModal-title">
					<Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p data-cy={'confirmationModal-content'}>{props.content}</p>
				</Modal.Body>
				<Modal.Footer>
					<div
						data-cy="confirmationModal-confirm"
						className="button button--danger"
						onClick={() => props.onClose(true)}
					>
						{t(prefix + 'accept')}
					</div>
					<div
						data-cy="confirmationModal-cancel"
						className="button button--outline"
						onClick={() => props.onClose(false)}
					>
						{t(prefix + 'decline')}
					</div>
				</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
}

ConfirmationModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	content: PropTypes.string.isRequired,
};

export { ConfirmationModal };
