import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ValidationMessage } from '../../shared/ValidationMessage';
import { DANGER } from '../../../constants/Variants';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { parseNumber } from '../../../classes/helpers/StringHelper';
import { INVALID_VALUE } from '../../../constants/ValidationErrors';
import { EMAIL, ONE_DECIMAL, ONLY_NUMBERS } from '../../../constants/RegEx';

export const ValidatedInput = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const validationPrefix = 'validation.';

	const [rules, setRules] = useState({});
	const [value, setValue] = useState('');

	useEffect(() => {
		resetValidation();
	}, [props.minLength, props.maxLength, props.max, props.min, props.pattern]);

	useEffect(() => {
		if (props.value) {
			setValue(props.value);
		}
	}, [props.value]);

	const getPatternValidationMessageContent = () => {
		let content;

		switch (props.pattern) {
			case EMAIL:
				content = t(validationPrefix + 'email');
				break;
			case ONLY_NUMBERS:
				content = t(validationPrefix + 'onlyNumbers');
				break;
			case ONE_DECIMAL:
				content = t(validationPrefix + 'decimalPrecisionError');
				break;
			default:
				content = '';
				break;
		}

		return content;
	};

	const resetValidation = () => {
		let tempRules = {};
		let validate = {};

		tempRules.required = props.required;

		if (props.minLength !== '') {
			tempRules.minLength = props.minLength;
		}

		if (props.maxLength !== '') {
			tempRules.maxLength = props.maxLength;
		}

		if (props.max !== 0) {
			validate[`max${props.name}`] = v => parseNumber(v) <= props.max;
		}

		if (props.min !== 0) {
			validate[`min${props.name}`] = v => parseNumber(v) >= props.min;
		}

		if (props.pattern !== '') {
			tempRules.pattern = props.pattern;
		}

		tempRules.validate = validate;
		setRules(tempRules);
	};

	const getLabel = () => {
		if (props.required) {
			return (
				<label>
					{props.label}
					<sup>*</sup>
				</label>
			);
		} else {
			return <label>{props.label}</label>;
		}
	};

	const onChange = changedValue => {
		if (props.onChange) {
			props.onChange(changedValue);
		}
	};

	const getInvalidValueMessage = () => {
		let message;

		if (props.name === 'length') {
			message = t(validationPrefix + 'invalidValueStockLength');
		}

		if (props.name === 'width') {
			message = t(validationPrefix + 'invalidValueStockWidth');
		}

		return message;
	};

	return (
		<div className={`form-group ${props.noMargin ? 'm-0-i' : ''}`}>
			{getLabel()}
			<input
				data-cy={props.dataCy}
				ref={props.register(rules)}
				defaultValue={value}
				name={props.name}
				type={props.type === 'number' ? 'number' : 'text'}
				placeholder={props.placeholder}
				disabled={props.disabled}
				readOnly={props.readonly}
				autoFocus={props.autoFocus}
				onChange={event => onChange(event.target.value)}
			/>
			{props.error && props.error.type === 'required' ? (
				<ValidationMessage
					dataCy={'validation-required-message'}
					content={t(validationPrefix + 'required')}
					variant={DANGER}
				/>
			) : null}
			{props.error && props.error.type === 'minLength' ? (
				<ValidationMessage
					dataCy={'validation-min-length-message'}
					content={t(validationPrefix + 'minLength', { minLength: rules.minLength })}
					variant={DANGER}
				/>
			) : null}
			{props.error && props.error.type === 'maxLength' ? (
				<ValidationMessage
					dataCy={'validation-max-length-message'}
					content={t(validationPrefix + 'maxLength', { maxLength: rules.maxLength })}
					variant={DANGER}
				/>
			) : null}
			{props.error && props.error.type === `min${props.name}` ? (
				<ValidationMessage
					dataCy={'validation-min-message'}
					content={t(validationPrefix + 'min', { min: props.min })}
					variant={DANGER}
				/>
			) : null}
			{props.error && props.error.type === `max${props.name}` ? (
				<ValidationMessage
					dataCy={'validation-max-message'}
					content={t(validationPrefix + 'max', { max: props.max })}
					variant={DANGER}
				/>
			) : null}
			{props.error && props.error.type === 'pattern' ? (
				<ValidationMessage
					dataCy={'validation-pattern-message'}
					content={getPatternValidationMessageContent()}
					variant={DANGER}
				/>
			) : null}

			{props.error && props.error.type === INVALID_VALUE ? (
				<ValidationMessage
					dataCy={'validation-invalid-value-message'}
					content={getInvalidValueMessage()}
					variant={DANGER}
				/>
			) : null}

			{props.error && props.error.type === 'custom' ? (
				<ValidationMessage dataCy={'validation-custom-message'} content={props.error.message} variant={DANGER} />
			) : null}

			{props.smallText ? (
				<small data-cy="form-infotext" className="form-infotext">
					{props.smallText}
				</small>
			) : null}
		</div>
	);
};

ValidatedInput.propTypes = {
	register: PropTypes.func.isRequired,
	error: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	readonly: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	required: PropTypes.bool,
	minLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	min: PropTypes.number,
	max: PropTypes.number,
	pattern: PropTypes.any,
	smallText: PropTypes.string,
	onChange: PropTypes.func,
	autoFocus: PropTypes.bool,
	dataCy: PropTypes.string,
	noMargin: PropTypes.bool,
};

ValidatedInput.defaultProps = {
	disabled: false,
	readOnly: false,
	error: {},
	required: false,
	minLength: '',
	maxLength: '',
	min: 0,
	max: 0,
	pattern: '',
	noMargin: false,
};
