import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppHeaderDropDown } from '../../headers/AppHeaderDropDown';
import { DANGER, SUCCESS } from '../../../constants/Variants';
import { AppHeaderDropdownItem } from '../../headers/AppHeaderDropdownItem';
import { DropdownDivider } from '../../headers/DropdownDivider';
import { AppHeader } from '../../headers/AppHeader';
import { exportPdf } from '../orders';
import { Offer } from '../../../classes/models/Offer';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { StockInfo } from '../../shared/StockInfo';
import { MINIMUM_STOCK_AMOUNT } from '../../../constants/StandardMeasurements';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveInfoModal } from '../../../actions/GeneralActions';
import { hasOneOfRoles } from '../../../classes/helpers/UserHelper';
import { ADMIN, SUPERVISOR } from '../../../constants/RoleNames';

export const OffersAppHeader = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.offers.';
	const addToOfferDropdownPrefix = 'pages.offers.addToOfferDropdown.';
	const appHeaderPrefix = 'headers.appHeader.';

	const [stockSquareMeters, setStockSquareMeters] = useState();
	const [shouldRenderStockInfo, setShouldRenderStockInfo] = useState(false);

	const { canEdit } = useSelector(state => state.generalReducer);

	const dispatch = useDispatch();

	useEffect(() => {
		const offerContainsStockConfiguration = props.offer.containsStockConfiguration();
		setShouldRenderStockInfo(offerContainsStockConfiguration);

		if (offerContainsStockConfiguration) {
			setStockSquareMeters(props.offer.getStockSquareMeter());
		}
	}, []);

	const openInfoModal = () => {
		dispatch(setActiveInfoModal(true, t(prefix + 'cannotExportPDFInsufficientStock')));
	};

	const exportPdfItem = () => {
		const hasConfigurations = props.offer.configurations.length || props.offer.outdoorConfigurations.length;
		const hasSufficientStockAmount = props.offer.containsStockConfiguration()
			? props.offer.getStockSquareMeter() >= MINIMUM_STOCK_AMOUNT
			: true;

		const onClick = hasSufficientStockAmount
			? () => exportPdf(props.offer, t(prefix + 'offerDownloadFailed'))
			: openInfoModal;

		if (hasConfigurations) {
			return (
				<AppHeaderDropdownItem
					dataCy="offersAppHeader-pdfButton"
					content={t(appHeaderPrefix + 'actions.exportPdf')}
					onClick={() => onClick()}
				/>
			);
		}
	};

	const mailPdfItem = () => {
		// TODO implement this feature
		// if (currentOffer.configurations.length > 0) {
		//     return <AppHeaderDropdownItem
		//         content={'Offerte mailen'}
		//         onClick={() => alert("ok")}/>
		// }
	};

	const duplicateOffer = () => {
		props.onDuplicateClick();
	};

	return (
		<AppHeader
			title={`${t(prefix + 'headerTitle')}: ${props.offer.companyBranch.name} - ${props.offer.name}`}
			onBackClick={() => props.onBackClick()}
			hasBadge={props.offer?.isNotDeliverable()}
			badgeContent={t(prefix + 'notDeliverable')}
			dataCy="offersAppHeader"
		>
			{shouldRenderStockInfo ? (
				<div className="m-r-1">
					<StockInfo amount={stockSquareMeters} />
				</div>
			) : null}

			{canEdit && (
				<AppHeaderDropDown
					dataCy="offersAppHeader-addToOfferButton"
					content={t(addToOfferDropdownPrefix + 'buttonContent')}
					variant={SUCCESS}
					icon="add"
				>
					<AppHeaderDropdownItem
						dataCy="offersAppHeader-addConfigurationButton"
						content={t(addToOfferDropdownPrefix + 'addConfiguration')}
						onClick={() => props.openConfigurationModal()}
					/>
					{/*<AppHeaderDropdownItem*/}
					{/*    content={t(addToOfferDropdownPrefix + 'addOutdoorConfiguration')}*/}
					{/*    onClick={() => history.push('/articles')}*/}
					{/*/>*/}
					{/*<AppHeaderDropdownItem*/}
					{/*    content={t(addToOfferDropdownPrefix + 'addAttachment')}*/}
					{/*    disabled*/}
					{/*/>*/}
				</AppHeaderDropDown>
			)}

			<AppHeaderDropDown dataCy="offersAppHeader-optionsButton" content={t(prefix + 'optionsButton')}>
				<AppHeaderDropdownItem
					content={t(appHeaderPrefix + 'actions.editOffer')}
					onClick={() => props.openOfferModal()}
					dataCy={'offersAppHeader-editOfferButton'}
				/>

				<AppHeaderDropdownItem
					dataCy={'offers-app-header-duplicate-button'}
					content={t(appHeaderPrefix + 'actions.duplicate')}
					onClick={() => duplicateOffer()}
				/>

				{exportPdfItem()}
				{mailPdfItem()}

				{hasOneOfRoles([ADMIN, SUPERVISOR]) && (
					<>
						<DropdownDivider />

						<AppHeaderDropdownItem
							dataCy={'offers-app-header-logs-button'}
							content={t(appHeaderPrefix + 'actions.openLogsOverview')}
							onClick={() => props.openLogsModal()}
						/>
					</>
				)}

				<DropdownDivider />

				<AppHeaderDropdownItem
					dataCy={'offers-app-header-delete-button'}
					content={t(appHeaderPrefix + 'actions.delete')}
					variant={DANGER}
					onClick={() => props.openDeleteOfferModal()}
				/>
			</AppHeaderDropDown>
		</AppHeader>
	);
};

OffersAppHeader.propTypes = {
	offer: PropTypes.instanceOf(Offer).isRequired,
	onBackClick: PropTypes.func.isRequired,
	openConfigurationModal: PropTypes.func.isRequired,
	openOfferModal: PropTypes.func.isRequired,
	openDeleteOfferModal: PropTypes.func.isRequired,
	openLogsModal: PropTypes.func.isRequired,
	onDuplicateClick: PropTypes.func.isRequired,
};
