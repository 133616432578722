import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConfirmationModal, NotchOperation, RadioButton, SectionTitle, VectorHelper } from '../../internal';
import notchLeft from '../../assets/img/notch-left.svg';
import notchRight from '../../assets/img/notch-right.svg';
import notchBack from '../../assets/img/notch-back.svg';
import notchFront from '../../assets/img/notch-front.svg';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useDispatch, useSelector } from 'react-redux';
import { setWindowIsLoading } from '../../actions/GeneralActions';
import { useForm } from 'react-hook-form';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { PROHIBITED_OPERATION_TYPES } from '../../constants/OperationTypes';
import { createOperation, deleteOperation, updateOperation } from './index';
import { BACK, BOTTOM, FRONT, LEFT, RIGHT, TOP } from '../../constants/ObjectSides';
import { getSideNameByPreset } from '../../classes/helpers/ObjectHelper';
import { parseNumber } from '../../classes/helpers/StringHelper';
import { ModalHolder } from './ModalHolder';

export function NotchModal(props) {
	const [confirmationModalIsActive, setConfirmationModalIsActive] = useState(false);
	const [selectedSide, setSelectedSide] = useState('');
	const [length, setLength] = useState('');
	const [availableSides, setAvailableSides] = useState([]);

	const { errors, register, handleSubmit } = useForm();

	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.notchModal.';

	const { currentConfiguration, currentPiece, currentOperation } = useSelector(state => state.offerReducer);
	const canEdit = useSelector(state => state.generalReducer.canEdit);

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.isActive && currentPiece) {
			const currentAvailableSides = currentPiece.getAvailableSides(PROHIBITED_OPERATION_TYPES.NOTCH);

			setAvailableSides(currentAvailableSides);

			if (currentOperation == null) {
				setSelectedSide(currentAvailableSides[0]);
			} else {
				setSelectedSide(currentOperation.side);
			}
		}
	}, [props.isActive, currentOperation]);

	const getImage = side => {
		let image;

		switch (side) {
			case FRONT:
			case BOTTOM:
				image = notchFront;
				break;
			case LEFT:
				image = notchLeft;
				break;
			case BACK:
			case TOP:
				image = notchBack;
				break;
			case RIGHT:
				image = notchRight;
				break;
		}

		return image;
	};

	const closeModal = fields => {
		if (!fields) {
			props.onClose();
			return;
		}

		if (currentOperation == null) {
			createNotch(fields);
		} else {
			updateNotch(fields);
			props.onClose(true);
		}
	};

	const createNotch = fields => {
		const parsedLength = parseNumber(fields.length);
		const parsedWidth = parseNumber(fields.width);
		const parsedDistance = parseNumber(fields.distance);

		let notch = new NotchOperation(0, selectedSide);
		notch.dimensions.length = isNaN(parsedLength) ? 0 : parsedLength;
		notch.dimensions.width = isNaN(parsedWidth) ? 0 : parsedWidth;
		notch.additionalDimension.value = isNaN(parsedDistance) ? 0 : parsedDistance;

		notch.position = VectorHelper.getVectorForNotch(currentPiece?.dimensions, notch);

		createOperation(notch, () => props.onClose(true), t(prefix + 'notchCreateFailed'));
	};

	const updateNotch = fields => {
		dispatch(setWindowIsLoading(true));

		const parsedLength = parseNumber(fields.length);
		const parsedWidth = parseNumber(fields.width);
		const parsedDistance = parseNumber(fields.distance);

		currentOperation.dimensions.length = isNaN(parsedLength) ? 0 : parsedLength;
		currentOperation.dimensions.width = isNaN(parsedWidth) ? 0 : parsedWidth;
		currentOperation.additionalDimension.value = isNaN(parsedDistance) ? 0 : parsedDistance;
		currentOperation.side = selectedSide;
		currentOperation.position = VectorHelper.getVectorForNotch(currentPiece?.dimensions, currentOperation);

		updateOperation(currentOperation, () => props.onClose(true), t(prefix + 'updateFailed'));
	};

	const deleteNotch = () => {
		deleteOperation(
			currentOperation,
			() => props.onClose(true),
			t(prefix + 'deleteSuccess'),
			t(prefix + 'deleteFailed'),
		);
	};

	const closeConfirmationModal = userHasAccepted => {
		if (userHasAccepted) {
			deleteNotch();
		}

		setConfirmationModalIsActive(false);
	};

	const onSideChange = value => {
		if (value === TOP || value === BOTTOM) return;

		setSelectedSide(value);
	};

	const renderInputFields = () => {
		let validationMaxLength;
		let validationMaxWidth;
		let validationMaxDistance;

		const minimumRemainingLengthAndWidth = 1;

		const maxLength = currentPiece?.dimensions.length - minimumRemainingLengthAndWidth;
		const maxWidth = currentPiece?.dimensions.width - minimumRemainingLengthAndWidth;

		if (selectedSide === FRONT || selectedSide === BACK) {
			validationMaxLength = maxLength;
			validationMaxWidth = maxWidth;
			console.log(length);
			validationMaxDistance = currentPiece?.dimensions.length - length;
		} else {
			validationMaxLength = maxWidth;
			validationMaxWidth = maxLength;
			validationMaxDistance = currentPiece?.dimensions.width - length;
		}

		if (validationMaxDistance < 0) validationMaxDistance = 0;

		return (
			<div className="row">
				<div className="col-4">
					<ValidatedInput
						dataCy={`notchModal-lengthInput`}
						register={register}
						name={'length'}
						label={t(prefix + 'notchLengthLabel')}
						placeholder={t(prefix + 'notchLengthPlaceholder')}
						value={currentOperation?.dimensions.length}
						disabled={!canEdit}
						onChange={value => setLength(value)}
						required={true}
						min={1}
						max={validationMaxLength}
						error={errors.length}
					/>
				</div>
				<div className="col-4">
					<ValidatedInput
						dataCy={`notchModal-widthInput`}
						register={register}
						name={'width'}
						label={t(prefix + 'notchWidthLabel')}
						placeholder={t(prefix + 'notchWidthPlaceholder')}
						value={currentOperation?.dimensions.width}
						disabled={!canEdit}
						required={true}
						min={1}
						max={validationMaxWidth}
						error={errors.width}
					/>
				</div>
				<div className="col-4">
					<ValidatedInput
						dataCy={`notchModal-distanceInput`}
						register={register}
						name={'distance'}
						label={t(prefix + 'notchDistanceLabel')}
						placeholder={t(prefix + 'notchDistancePlaceholder')}
						value={currentOperation?.additionalDimension.value}
						disabled={!canEdit}
						min={0}
						max={validationMaxDistance}
						error={errors.distance}
					/>
				</div>
			</div>
		);
	};

	const renderSideSelect = () => {
		return (
			<>
				<SectionTitle content={t(prefix + 'sideTitle')} />

				{[FRONT, LEFT, BACK, RIGHT].map((side, index) => {
					const sideName = getSideNameByPreset(currentConfiguration?.options.preset, side);
					return (
						<RadioButton
							dataCy={`notchModal-${side.toLowerCase()}`}
							key={`notchSideRadioButton${index}`}
							name="notchSide"
							content={t('constants.objectSides.' + sideName)}
							image={getImage(sideName)}
							checked={selectedSide === side}
							onChange={() => onSideChange(side)}
							disabled={!canEdit || !availableSides.includes(side)}
						/>
					);
				})}
			</>
		);
	};

	const renderFooter = () => {
		let secondaryButtonStyle = 'button button--outline';
		let secondaryButtonContent = t(prefix + 'cancelButton');
		let secondaryButtonOnClick = () => closeModal();

		if (currentOperation != null && canEdit) {
			secondaryButtonStyle = 'button button--danger';
			secondaryButtonContent = t(prefix + 'deleteButton');
			secondaryButtonOnClick = () => setConfirmationModalIsActive(true);
		}

		const submitButton = (
			<button
				data-cy="notchModal-submitButton"
				key={'notchModalSubmitButton'}
				className="button button--primary"
				type="submit"
				form="notchForm"
			>
				{t(prefix + 'doneButton')}
			</button>
		);

		let buttons = [
			<button
				data-cy="notchModal-cancelButton"
				key={'notchModalCancelButton'}
				type="button"
				className={secondaryButtonStyle}
				onClick={secondaryButtonOnClick}
			>
				{secondaryButtonContent}
			</button>,
		];

		if (canEdit) {
			buttons.unshift(submitButton);
		}

		return buttons;
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<ConfirmationModal
				isActive={confirmationModalIsActive}
				onClose={userHasAccepted => closeConfirmationModal(userHasAccepted)}
				content={t(prefix + 'confirmationModalContent')}
			/>

			<Modal show={props.isActive} onHide={() => closeModal()} animation={true} data-cy="notch-modal">
				<Modal.Header className="modal-header">
					<Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id="notchForm" onSubmit={handleSubmit(closeModal)}>
						<p>{t(prefix + 'description')}</p>

						{renderSideSelect()}

						<SectionTitle content={t(prefix + 'dimensionTitle')} />
						{renderInputFields()}
					</form>
				</Modal.Body>
				<Modal.Footer>{renderFooter()}</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
}

NotchModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
