import * as React from 'react';
import PropTypes from 'prop-types';
import { ValidationMessage } from '../../shared/ValidationMessage';

export const CheckboxWithIcon = props => {
	return (
		<div className={'m-b-2 ' + props.className}>
			<div className="form-group form-group--toggle">
				<label>
					<div className="d-flex space-between w-100">
						<span className="d-flex align-items-center">
							<input
								type="checkbox"
								name="checkboxWithIcon"
								checked={props.checked}
								disabled={props.disabled}
								onChange={() => props.onChange()}
								data-cy={props.dataCy}
							/>

							{props.image ? <img src={props.image} alt={props.content} /> : null}
							{props.content ? props.content : ''}
						</span>
						<span className="m-r-1">{props.children}</span>
					</div>
				</label>
				{props.smallText !== '' ? (
					<ValidationMessage
						content={props.smallText}
						variant={props.smallTextVariant}
						dataCy={props.dataCySmallText}
					/>
				) : null}
			</div>
		</div>
	);
};

CheckboxWithIcon.propTypes = {
	content: PropTypes.string,
	image: PropTypes.node,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	smallText: PropTypes.string,
	smallTextVariant: PropTypes.string,
	register: PropTypes.func,
	required: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	dataCy: PropTypes.string,
	dataCySmallText: PropTypes.string,
};

CheckboxWithIcon.defaultProps = {
	checked: false,
	content: '',
	smallText: '',
	className: '',
};
